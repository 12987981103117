import React from "react"
import Layout from "../components/layout"
import {
  Title,
  Overview,
  Toolset,
  Visit,
  WorkedWith,
  Project,
} from "../components/parts"

const KnowApp = () => (
  <Layout title="Know">
    <Title heading="Know" sub="Work management & messaging" />
    <Overview
      project="know-app"
      text={[
        "Know app helps blue-collar workers in their everyday jobs, from to-do lists and alerts to issue tracking.",
        "I helped the Know app with a summary dashboard for issue tracking, prototypes of a Whatsapp bot for hiring, and an employee tracking system using beacons.",
      ]}
    />
    <Project
      heading="Floor tracking"
      sub="Tracking employee activity around a floor using beacons"
      style={{ marginBottom: 0 }}
    />
    <div className="section-bottom">
      <img src="/images/know-app/2.png" alt="dashboard" />
    </div>
    <Toolset tools={["reactjs", "firebase"]} />
    <Project
      heading="Whatsapp Hiring bot"
      sub="Quick hiring of blue collar workers via Whatsapp"
    />
    <Toolset tools={["twilio", "reactjs", "firebase"]} />
    <Project
      heading="Issue tracking dashboard"
      sub="Metrics for employers"
      style={{ marginBottom: 0 }}
    />
    <div className="section-bottom">
      <img src="/images/know-app/1.png" alt="dashboard" />
    </div>
    <Toolset tools={["reactjs", "firebase"]} />
    <Visit project="know-app" />
    <WorkedWith project="know-app" />
  </Layout>
)

export default KnowApp
